.uploader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 0;

  & .ant-upload {
    position: relative;
    margin: 0;
    width: 350px;
    height: 350px;
  }

  & .ant-upload-list-picture-card .ant-upload-list-item {
    width: 350px;
    height: 350px;
  }

  &__icon {
    font-size: 32px;
  }

  &__fullPic {
    width: 100%;
  }

  &__actions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
  }
}
