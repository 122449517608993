@import "~antd/dist/antd.css";
@import "./variables";
@import "./login-form";
@import "./layout";
@import "./menu";
@import "./description";
@import "./uploader";
@import "./grid";
@import "./card";
@import "./form";

#root {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.content {
  display: flex;

  &_center {
    align-items: center;
    justify-content: center;
  }
}



//error - #f5222d
//warning - #faad14
//success - #52c41a
//primary - #1890ff
