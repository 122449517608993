.card {
  display: flex;
  flex-direction: column;

  &__inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 10px;
  }

  &__wrapper {
    padding: 20px 0;

    &_borderBottom {
      border-bottom: 1px solid $border-color;
    }

    &_grow {
      flex-grow: 1;
    }
  }

  &__dsc {
    height: 100% !important;
  }
}
