.menu {
  position: absolute;
  top: 0;
  bottom: 0;
  transform: translateX(-200px);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  @media (min-width: $md) {
    transform: none;
    transition: none;
    top: 62px;
  }

  &__inner {
    padding-top: 20px;
    border-right: none;
  }

  &__slider {
    overflow: auto;
    position: fixed;
    left: 0;
    z-index: 1;
    height: 100%;
  }

  &__handle {
    position: absolute;
    left: 200px;
    top: 72px;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    border-radius: 0 4px 4px 0;
    width: 41px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    @media (min-width: $md) {
      display: none;
    }
  }

  &__handleIcon {
    position: relative;
    width: 14px;
    height: 2px;
    background: #333;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      background: #333;
      width: 100%;
      height: 2px;
      transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    &:before {
      top: -5px;
    }

    &:after {
      top: 5px;
    }
  }

  &_open {
    transform: translateX(0);
    & .menu__slider {

    }

    & .menu__handleIcon {
      background: transparent;

      &:before {
        transform: translateY(5px) rotate(45deg);
      }

      &:after {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}
