.form {

  &__item {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 0;
  }

  &_horizontal {
    .form__item {
      flex-direction: row;
    }

    .ant-form-item-control-wrapper {
      flex-grow: 1;
    }
  }

  & .ant-form-item-label {
    text-align: left;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin: 0 10px;

    &:last-child {
      margin: 0;
    }
  }
}
