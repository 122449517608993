.description {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 2.5;
  white-space: nowrap;

  &__content {
    color: rgba(0,0,0,0.65);
    padding-left: 10px;
  }
}
