.grid {
  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;

    &_justify {
      justify-content: space-between;
    }

    &_end {
      align-items: flex-end;
    }
  }
}
