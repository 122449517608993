.login {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  &__form {
    width: 100%;
    background-color: white;
    max-width: 400px;
    padding: 20px;
  }

  &__icon {
    color: rgba(0,0,0,.25);
  }

  &__button {
    width: 100%;
  }

  &__link{
    text-align: right;
    display: inline-block;
    width: 100%;
    padding-bottom: 10px;
  }
}
