.layout {
  display: flex;
  width: 100%;

  &_menuOpen {
    overflow: hidden;

    & .layout__wrapper {
      transform: translateX(200px);
    }
  }

  &__cover {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: black;
    opacity: 0.3;
    z-index: 11;

    @media (min-width: $md) {
     display: none;
    }
  }

  &__wrapper {
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);

    @media (min-width: $md) {
      transition: none;
      transform: none;
    }
  }

  &__header {
    z-index: 10;
    box-shadow: 0 2px 8px #f0f1f2;

    & .ant-page-header-content {
      padding-top: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 0;
    margin-left: 10px;
  }

  &__column {
    display: flex;

    &_right {
      justify-content: flex-end;
    }
  }

  &__ava {
    margin: 0 10px;
  }

  &__button {
    padding: 0 8px;
    box-shadow: none;
  }

  &__content {
    position: relative;
    margin: 24px 16px;
    overflow: initial;

    @media (min-width: $md) {
      transform: none;
      margin-left: 224px;
    }
  }

  &__inner {
    padding: 24px;
    background: white;
    height: 100%;
  }

  @media (min-width: $md) {
    overflow: initial;
  }

  &__logout {
    display: flex;
    padding: 0 8px;
    border: 1px solid $primary-color;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}
